import React from 'react';
import AppBar from '../components/AppBar';
import './styles.css';

const DefaultLayout = ({ children }) => {
  return (
    <>
      <AppBar />
      {children}
    </>
  );
};

export default DefaultLayout;
