import axios from 'axios';

export const isBrowser = () => typeof window !== 'undefined';

export const getUser = () =>
  isBrowser() && window.localStorage.getItem('gatsbyUser')
    ? JSON.parse(window.localStorage.getItem('gatsbyUser'))
    : {};

const setUser = (user) =>
  window.localStorage.setItem('gatsbyUser', JSON.stringify(user));

export const loginByFacebookAccessToken = async (accessToken) => {
  const { data } = await axios.get(
    `${process.env.GATSBY_BACKEND_URL}/api/auth/facebook/callback?access_token=${accessToken}`
  );

  setUser({ ...data.user, jwt: data.jwt });

  return data;
};

export const isLoggedIn = () => {
  const user = getUser();

  return !!user.username;
};

export const logout = () => {
  setUser({});
};
